<template>
  <Layout is-demo>
    <div class="">
      <h1 class="text-h4">Quaterly Report Filling
      </h1>
      <div class="body-2 grey--text">
        <b>Reporting Period:</b> {{ reportingPeriod }}
      </div>
      <v-divider class="my-2"></v-divider>
      <v-alert
        dense
        text
        type="warning"
        border="left"
        color="green"
      >Section 11 of the NFQC, 2019 Regulations mandates you - <b>{{ companyName }}</b> to report 
        all fertilizer products produced, purchased, distributed or sold in Nigeria. And to pay 
        an inspection fee per metric ton of your production or importotion per quarter
        <br/>
        Quarterly reports are due on or before Fifteen (20) days following the close of the filing 
        period and upon filing of the report shall pay the required inspection fee. If the tonnage
        report is not filed and the payment of inspection fees is not made within Fifteen (20) days 
        after the due date, a collection fee amounting to ten percent (10%) of the amount due shall
        be added to the amount due.

      </v-alert>
    </div>
    <v-row>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title>Last quarter summary</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row class="caption pa-0 ma-0">
              <v-col sm="4" class="font-weight-bold pa-0 ma-0"> Total Purchases :</v-col>
              <v-col sm="6" class="pa-0 ma-0"> 1234</v-col>
            </v-row>
            <v-row class="caption pa-0 ma-0">
              <v-col sm="4" class="font-weight-bold pa-0 ma-0"> Total Sales :</v-col>
              <v-col sm="6" class="pa-0 ma-0"> 1234</v-col>
            </v-row>
            <v-row class="caption pa-0 ma-0">
              <v-col sm="4" class="font-weight-bold pa-0 ma-0"> Penalty :</v-col>
              <v-col sm="6" class="pa-0 ma-0"> 1234</v-col>
            </v-row>
            <v-row class="caption pa-0 ma-0">
              <v-col sm="4" class="font-weight-bold pa-0 ma-0"> Inspection fee :</v-col>
              <v-col sm="6" class="pa-0 ma-0"> 1234</v-col>
            </v-row>
            <v-row class="caption pa-0 ma-0">
              <v-col sm="4" class="font-weight-bold pa-0 ma-0"> Total fee :</v-col>
              <v-col sm="6" class="pa-0 ma-0"> 1234</v-col>
            </v-row>
            <v-row class="caption pa-0 ma-0">
              <v-col sm="4" class="font-weight-bold pa-0 ma-0"> Comments :</v-col>
              <v-col sm="6" class="pa-0 ma-0"> No comments</v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title>Current quarter summary</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row class="caption pa-0 ma-0">
              <v-col sm="4" class="font-weight-bold pa-0 ma-0"> Total Purchases :</v-col>
              <v-col sm="6" class="pa-0 ma-0"> 1234</v-col>
            </v-row>
            <v-row class="caption pa-0 ma-0">
              <v-col sm="4" class="font-weight-bold pa-0 ma-0"> Total Sales :</v-col>
              <v-col sm="6" class="pa-0 ma-0"> 1234</v-col>
            </v-row>
            <v-row class="caption pa-0 ma-0">
              <v-col sm="4" class="font-weight-bold pa-0 ma-0"> Penalty :</v-col>
              <v-col sm="6" class="pa-0 ma-0"> 1234</v-col>
            </v-row>
            <v-row class="caption pa-0 ma-0">
              <v-col sm="4" class="font-weight-bold pa-0 ma-0"> Inspection fee :</v-col>
              <v-col sm="6" class="pa-0 ma-0"> 1234</v-col>
            </v-row>
            <v-row class="caption pa-0 ma-0">
              <v-col sm="4" class="font-weight-bold pa-0 ma-0"> Total fee :</v-col>
              <v-col sm="6" class="pa-0 ma-0"> 1234</v-col>
            </v-row>
            <v-row class="caption pa-0 ma-0">
              <v-col sm="4" class="font-weight-bold pa-0 ma-0"> Comments :</v-col>
              <v-col sm="6" class="pa-0 ma-0"> No comments</v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-row
          align="center"
          justify="space-around"
        >
          <v-btn-toggle>
            <v-btn text outlined color="primary" @click="callPurchasesReportPage">
              View or File Purchase Report
            </v-btn>
            <v-btn text outlined color="primary" @click="callSalesReportPage">
              View or File Sales Report
            </v-btn>
            <v-btn text outlined color="primary" @click="callZeroFilingPage">
              File A Zero Report
            </v-btn>
          </v-btn-toggle>
        </v-row>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" right>
      {{ snackbartext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </Layout>
</template>

<script>
import Layout from '@/nfmp_components/layouts/MainPortalLayout'
import httpServ from '../services/HTTPService'

export default {
  components: {
    Layout
  },
  data() {
    return {
      memberData:{},
      currentLOBDetails:{},
      prePostData:{},
      pageData:{},
      snackbar: false,
      snackbartext:'',

      companyName:'',
      reportingPeriod :  '',

      headers: [
        {
          text: 'FirstName',
          align: 'start',
          sortable: false,
          value: 'first_name'
        }
      ]
    }
  },
  created() {
    this.memberData = httpServ.getSessionJson('memberData')
    this.companyName = this.memberData.company_name
    this.currentLOBDetails.memberStatus =
      this.memberData.lobs[this.memberData.displayLOB].membership_status
    this.currentLOBDetails.LOBId =
      this.memberData.lobs[this.memberData.displayLOB].line_of_business_id
    this.currentLOBDetails.LOBname =
      this.memberData.lobs[this.memberData.displayLOB].line_of_business_name
    this.prePostData = {
      membership_id: this.memberData.membership_id,
      LOB_id:
        this.memberData.lobs[this.memberData.displayLOB].line_of_business_id
    }

    this.getPageData()
  },
  mounted() {},
  methods: {
    callPurchasesReportPage: function() {
      this.$router.push({ name: 'purchasesReportFiling' })
    },
    callSalesReportPage: function() {
      this.$router.push({ name: 'salesReportFiling' })
    },
    callZeroFilingPage: function() {
      this.$router.push({ name: 'zeroFiling' })
    }, getPageData: function (mode) {

      const url = httpServ.getTonnageReportUrl(
        '/tonnageReport/member/getQRSummary'
      )
      const resp = httpServ.postFetchWithToken(
        url,
        this.prePostData,
        this.memberData.jwt
      )

      resp.then((result) => {
        const { schedules } = result.data

        console.log(schedules)
        this.dates = result.data.schedules
        const start_date = this.$moment(
          schedules.start_date,
          'YYYY-MM-DD'
        ).format('ddd Do, MMM YYYY')
        const end_date = this.$moment(schedules.end_date, 'YYYY-MM-DD').format(
          'ddd Do, MMM YYYY'
        )

        this.reportingPeriod = start_date + ' to ' + end_date
        /*this.zeroReportFilingDate = this.$moment(
          schedules.start_date,
          'YYYY-MM-DD'
        )
          .add(this.filingDate, 'days')
          .format('ddd Do, MMM YYYY')
        const now = this.$moment().format('YYYY-MM-DD')
        const fileDate = this.$moment(schedules.start_date)
          .add(this.filingDate, 'days')
          .format('YYYY-MM-DD')

        this.zeroFilingState = this.$moment(fileDate).isAfter(now)*/
      })
    }
  }
}
</script>

<style scoped>
</style>
