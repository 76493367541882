<template>
  <div>
    <ClearanceCertificate />
  </div>
</template>

<script>
import ClearanceCertificate from '@/nfmp_components/ClearanceCertificate.vue'
export default {
  components: {
    ClearanceCertificate
  }
}
</script>