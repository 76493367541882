<template>
  <div>
    <ZeroFiling/>
  </div>
</template>

<script>
import ZeroFiling from '@/nfmp_components/ZeroFiling.vue'
export default {
  components: {
    ZeroFiling
  }
}
</script>