<template>
  <div>
    <GroupRegistration />
  </div>
</template>

<script>
import GroupRegistration from '@/nfmp_components/GroupRegistration.vue'
export default {
  components: {
    GroupRegistration
  }
}
</script>