<template>
  <Layout is-demo>
    <div class="">
      <h1 class="text-h4">Quaterly Report Zero Filing</h1>
      <div class="body-2 grey--text">
        <b>Reporting Period:</b> {{ reportingPeriod }}
      </div>
      <v-divider class="my-2"></v-divider>
      <v-alert
        dense
        text
        type="warning"
        border="left"
        color="green"
      >
        Zero quaterly report should only be filed in cases where both purchases
        and sales are not carried out for that quater i.e
        <b>{{ reportingPeriod }}</b>. And failure to file after the expiration of the delinquent date i.e
        <b>{{ delinquentDate }}</b> will attract a collection fee amounting to
        ten percent (10%) percent of the annual fee or 5 penalty units,
        whichever is greater, should your report (s) be postmarked later than
        the delinquent date.
      </v-alert>
      <div>
        Zero quaterly report filing is enabled only {{ filingDate }} days i.e
        <b>{{ zeroReportFilingDate }}</b> after commencement of this quater.<br />
        To file a zero quaterly report click the button below:
        <v-btn
          block
          class="ma-2 primary"
          :disabled="zeroFilingState"
          large
          :loading="formPostLoading"
          @click="postData"
        >File a zero report</v-btn>
      </div>
    </div>
    <v-snackbar v-model="snackbar" right>
      {{ snackbartext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </Layout>
</template>

<script>
import Layout from '@/nfmp_components/layouts/MainPortalLayout'
import httpServ from '../services/HTTPService'

export default {
  components: {
    Layout
  },
  data() {
    return {
      memberData: {},
      currentLOBDetails: {},
      prePostData: {},
      pageData: {},
      snackbar: false,
      snackbartext: '',
      zeroFilingState: true,
      filingDate: 60,
      dates:{},

      formPostLoading: false,
      reportingPeriod: '',
      delinquentDate: '',
      zeroReportFilingDate: '',
      headers: [
        {
          text: 'FirstName',
          align: 'start',
          sortable: false,
          value: 'first_name'
        }
      ]
    }
  },
  created() {
    this.memberData = httpServ.getSessionJson('memberData')
    this.currentLOBDetails.memberStatus =
      this.memberData.lobs[this.memberData.displayLOB].membership_status
    this.currentLOBDetails.LOBId =
      this.memberData.lobs[this.memberData.displayLOB].line_of_business_id
    this.currentLOBDetails.LOBname =
      this.memberData.lobs[this.memberData.displayLOB].line_of_business_name
    this.prePostData = {
      membership_id: this.memberData.membership_id,
      LOB_id:
        this.memberData.lobs[this.memberData.displayLOB].line_of_business_id
    }
    this.getPageData()
  },
  mounted() {},
  methods: {
    callPurchaseReportPage: function () {
      this.$router.push({ name: 'purchaseReportFiling' })
    },
    callExemptionReportPage: function () {
      this.$router.push({ name: 'exemptionReportFiling' })
    },
    callSalesReportPage: function () {
      this.$router.push({ name: 'salesReportFiling' })
    },
    getPageData: function (mode) {
      const url = httpServ.getTonnageReportUrl(
        '/tonnageReport/member/getQuaterlySchedule'
      )
      const resp = httpServ.postFetchWithToken(
        url,
        this.prePostData,
        this.memberData.jwt
      )

      resp.then((result) => {
        const { schedules } = result.data

        this.dates = result.data.schedules
        const start_date = this.$moment(
          schedules.start_date,
          'YYYY-MM-DD'
        ).format('ddd Do, MMM YYYY')
        const end_date = this.$moment(schedules.end_date, 'YYYY-MM-DD').format(
          'ddd Do, MMM YYYY'
        )

        this.delinquentDate = this.$moment(
          schedules.due_date,
          'YYYY-MM-DD'
        ).format('ddd Do, MMM YYYY')

        this.reportingPeriod = start_date + ' to ' + end_date
        this.zeroReportFilingDate = this.$moment(
          schedules.start_date,
          'YYYY-MM-DD'
        )
          .add(this.filingDate, 'days')
          .format('ddd Do, MMM YYYY')
        const now = this.$moment().format('YYYY-MM-DD')
        const fileDate = this.$moment(schedules.start_date)
          .add(this.filingDate, 'days')
          .format('YYYY-MM-DD')

        this.zeroFilingState = this.$moment(fileDate).isAfter(now)
      })
    },
    getData: function () {
      //console.log(this.dates)
      const data = {
        membership_id: this.memberData.membership_id,
        start_date:this.dates.start_date,
        end_date:this.dates.end_date,
        line_of_business:
          this.memberData.lobs[this.memberData.displayLOB].line_of_business_id
      }

      return data
    },
    postData: function () {
      const postData = this.getData()

      this.formPostLoading = true

      const url = httpServ.getTonnageReportUrl(
        '/tonnageReport/member/zeroFiling'
      )
      const resp = httpServ.postFetchWithToken(
        url,
        postData,
        this.memberData.jwt
      )

      resp.then((result) => {
        if (result.status === 200) {
          this.snackbartext = 'Entry was successful!'
          this.snackbar = true
          this.signinBtnText = 'Successful'
          this.formPostLoading = false
        } else if (result.status === 422) {
          if (result instanceof SyntaxError) {
            // Unexpected token < in JSON
            //console.log('There was a SyntaxError', result)//checker
            this.snackbartext = result.message
            this.snackbar = true
          } else {
            //console.log('There was an error', result) //Checker
            this.snackbartext = result.message
            this.snackbar = true
          }
          this.formBtnLoader = false
        } else {
          this.snackbartext = result.message
          this.snackbar = true
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
