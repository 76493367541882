<template>
  <v-layout justify-center>
    <v-btn-toggle>
      <v-btn
        color="primary"
        large
        @click="function(){
          $router.push({ name: 'quaterlyReportFiling' })
        }"
      >
        Back To Quaterly Report Filing
      </v-btn>
      <v-btn text outlined color="primary" @click="callPurchasesReportPage">
        View or File Purchase Report
      </v-btn>
      <v-btn text outlined color="primary" @click="callSalesReportPage">
        View or File Sales Report
      </v-btn>
      <v-btn text outlined color="primary" @click="callZeroFilingPage">
        File A Zero Report
      </v-btn>
    </v-btn-toggle>
  </v-layout>
</template>
<script>
export default {}
</script>