<template>
  <Layout is-demo>
    <div class="">
      <h1 class="text-h4">Farmers Registration</h1>
      <v-divider class="my-2"></v-divider>
    </div>
    <v-form ref="form" v-model="valid" fast-fail>

      <v-card class="mb-3">
        <v-card-title>Farmer's Bio</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="3">
              <v-text-field
                v-model="first_name"
                label="Farmer First Name *"
                :rules="namesRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <v-text-field
                v-model="surname"
                label="Farmer Surname *"
                :rules="namesRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <v-text-field
                v-model="phone"
                :rules="phoneRules"
                :counter="11"
                label=" Phone Number *"
                maxlength="11"
                required
              ></v-text-field>
            </v-col>
            <v-col class="d-flex" cols="12" sm="12" md="3">
              <v-select
                v-model="gender"
                :items="gender_arr"
                label="Gender"
                item-text="title"
                item-value="id"
                :rules="[(v) => !!v || 'Item is required']"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-select
                v-model="state"
                return-object
                item-text="title"
                item-value="id"
                :items="state_arr"
                :rules="[(v) => !!v || 'Item is required']"
                label="Residence State *"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-select
                v-model="lga"
                return-object
                item-text="title"
                item-value="id"
                :items="lga_arr"
                :rules="[(v) => !!v || 'Item is required']"
                label="Residence LGA *"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                v-model="ward"
                label="Ward *"
                :rules="namesRules"
                hint="Must be at least 4 character long"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="home_address"
                :rules="addressRules"
                label="Home Address *"
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mb-3">
        <v-card-title>Identification</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="4">
              <v-select
                v-model="means_of_identification_type"
                return-object
                item-text="title"
                item-value="id"
                :items="means_of_identification_type_arr"
                :rules="[(v) => !!v || 'Item is required']"
                label="Means of Identification *"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                v-model="IDNumber"
                :rules="[(v) => !!v || 'Item is required']"
                label="ID Number *"
                hint="ID Number"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-select
                v-model="corperative"
                return-object
                item-text="title"
                item-value="id"
                :items="corperative_arr"
                label="Corperative"
                required
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mb-3">
        <v-card-title>Farm details</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="8">
              <v-text-field
                v-model="farm_address"
                :rules="addressRules"
                label="Farm location/address *"
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                v-model="farm_size"
                :counter="11"
                label="Farm Size *"
                maxlength="11"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="farm_borders"
                :rules="addressRules"
                label="Farm Borders *"
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                v-model="long"
                label="Long *"
                :rules="namesRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                v-model="lat"
                label="Lat *"
                :rules="namesRules"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mb-3">
        <v-card-title>Commodity</v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="12">
              <h2 class="text-h6 mb-2">
                Select the ones you are producing 
              </h2>
              <v-chip-group
                v-model="selections"
                center-active
                multiple
                column
                active-class="green--text text--accent-5 "
              >
                <v-chip
                  v-for="item in commodities_arr"
                  :key="item.id"
                  :value="item.id"
                  filter
                  outlined
                >
                  {{ item.title }}
                </v-chip>
              </v-chip-group>
            </v-col>
            
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mb-3">
        <v-card-title>Group Lead</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-select
                v-model="lead_type"
                :items="lead_arr"
                label="Lead Status"
                :rules="[(v) => !!v || 'Item is required']"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                v-model="lead_phone"
                :rules="phoneRules"
                :counter="11"
                label="Lead Phone Number *"
                maxlength="11"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                v-model="lead_first_name"
                label="Lead First Name *"
                :rules="namesRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                v-model="lead_surname"
                label="Lead Surname *"
                :rules="namesRules"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-row justify="center" align="center">
        <v-col cols="12" sm="12" md="6" class="text-center">
          <v-btn
            rounded
            block
            :loading="loading"
            :disabled="loading"
            color="primary"
            large
            @click="postData()"
          >
            Submit 
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-snackbar v-model="snackbar" right>
      {{ snackbartext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </Layout>
</template>

<script>
import Layout from '@/nfmp_components/layouts/MainPortalLayout'
import httpServ from '../services/HTTPService'

export default {
  components: {
    Layout
  },
  data() {
    return {
      memberData: {},
      currentLOBDetails: {},
      prePostData: {},
      pageData: {},
      snackbar: false,
      snackbartext:'',

      isNotLoaded: true,
      absolute: true,
      opacity: 1,
      overlay: false,
      dense: false,
      fixedHeader: false,
      height: 300,
      loading: false,
    
      first_name:'',
      surname:'',
      phone:'',
      gender:'',
      state:'',
      lga:'',
      ward:'',
      home_address:'',
      means_of_identification_type:'',
      identification_id:'',
      corperative:'',
      farm_address:'',
      farm_size:'',
      farm_borders:'',
      long:'',
      lat:'',
      commodities_arr:[],
      lead_type:'',
      lead_phone:'',
      lead_first_name:'',
      lead_surname:'',

      gender_arr: [],
      means_of_identification_type_arr:[],
      corperative_arr: [],
      state_arr: [],
      state_model: '',
      lead_type_arr:[],
      lga_arr: [],
      lga_model: '',
      selections: [],
      products_arr:[],
      lead_arr: ['Village head','Value chain leader'],
      IDNumber:'',
    
      valid: '',

      formItems: [],
      formId: 0,
      stateItems: [],
      stateItemsId: 0,
    
      items: [],

      corperativeNameRules: [
        (v) => !!v || 'Corperative name is required',
        (v) =>
          (v && v.length >= 4) ||
        'Corperative name must be greater than 4 characters'
      ],
      namesRules: [
        (v) => !!v || 'Name is required',
        (v) =>
          (v && v.length >= 2) ||
        'Mame must be greater than 2 characters'
      ],
      phoneRules: [
        (v) => !!v || 'Phone Number is required',
        (v) => /^\d{11}$/.test(v) || 'Phone number be must be valid',
        (v) => (v && v.length <= 11) || 'Phone Number must be 11 figures'
      ],
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      addressRules: [
        (v) => !!v || 'Name is required',
        (v) =>
          (v && v.length >= 15) ||
        'Mame must be greater than 15 characters'
      ]
    }
  },
  created() {
    this.memberData = httpServ.getSessionJson('memberData')
    this.getFormOption()

  },
  mounted() {},
  methods: {
    getFormOption: function() {
      //const formUrl = httpServ.getFarmerEnumerationtUrl('/enumeration/drySession/formOptions/bios')
      //const resValue2 = httpServ.makeRequest(formUrl, 'GET')
      const formUrl = httpServ.getGuestUrl('/formOptions/farmers/corperatives')
      const resValue2 = httpServ.makeRequest(formUrl, 'GET')
      
      resValue2.then((result) => {
        console.log('our select is ', result)
        if (result.status === 200) {
          /*this.state_arr = result.data.states
          this.lga_arr = result.data.lgas
          this.means_of_identification_type_arr = result.data.identifications
          this.gender_arr = result.data.genders
          this.lead_type_arr = result.data.leads
          this.commodities_arr = result.data.comodities*/

          this.state_arr = result.data.state
          this.lga_arr = result.data.lga
          this.means_of_identification_type_arr = result.data.means_of_identification
          this.gender_arr = result.data.gender
          this.lead_type_arr = result.data.leads
          this.commodities_arr = result.data.products
          //console.log(this.selection)
        } else {
          //alert(result.message)
          this.snackbartext = result.message
          this.snackbar = true
        }
      })
    },
    getNewData: function() {
      const data = {
        status: 'new',
        memberId:'',
        first_name:this.first_name,
        surname:this.surname,
        phone:this.phone,
        gender:this.gender,
        state:this.state,
        lga:this.lga,
        ward:this.ward,
        home_address:this.home_address,
        means_of_identification_type:this.means_of_identification_type,
        identification_id:this.identification_id,
        //corperative:this.corperative,
        farm_address:this.farm_address,
        farm_size:this.farm_size,
        farm_borders:this.farm_borders,
        //long:'',
        //lat:'',
        commodities:this.selections,
        lead_type:this.lead_type,
        lead_phone:this.lead_phone,
        lead_first_name:this.lead_first_name,
        lead_surname:this.lead_surname
      }

      return data
    },
    getUpdatedData: function() {
      const data = {}
      
      return data
    },
    postData: function() {
      const postData = this.postStatus === 'new' ? this.getNewData : this.getUpdatedData()
      
      this.loading = true
      setTimeout(() => {
        this.$router.push({ name: 'farmersRegistration' })
        this.snackbartext = 'Your submission was successful'
        this.snackbar = true
        window.location.reload()
      }, 5000)

      /*if (this.$refs.form.validate()) {
        const url = httpServ.getFarmerEnumerationtUrl('/enumeration/drySession/saveFarmer')
        const resp = httpServ.postFetch(url,postData)

        resp.then((result ) => {
          if (result.status === 200) {
            this.snackbartext = 'Your submission was successful'
            this.snackbar = true
          }
        })
      }*/
    }
  }
}
</script>

<style scoped></style>
