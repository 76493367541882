<template>
  <Layout is-demo>
    <div class="">
      <h1 class="text-h4">Entry Summary</h1>
      <v-divider class="my-2"></v-divider>
    </div>
   
    <v-snackbar v-model="snackbar" right>
      {{ snackbartext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </Layout>
</template>

<script>
import Layout from '@/nfmp_components/layouts/MainPortalLayout'
//import httpServ from '../services/HTTPService'

export default {
  components: {
    Layout
  },
  data() {
    return {
      memberData:{},
      currentLOBDetails:{},
      prePostData:{},
      pageData:{},
      snackbar: false
    }
  },
  created() {
    //this.memberData = httpServ.getSessionJson('memberData')
  },
  mounted() {},
  methods: {}
}
</script>

<style scoped>
</style>
