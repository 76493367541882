<template>
  <Layout is-demo>
    <div class="">
      <h1 class="text-h4">Group Registration</h1>
      <v-divider class="my-2"></v-divider>
    </div>
    <v-form ref="form" v-model="valid" fast-fail>

      <v-card class="mb-3">
        <v-card-title>Company details</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="8">
              <v-text-field
                v-model="company_name"
                label="COmpany Name *"
                :rules="namesRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-select
                v-model="coperative_type"
                return-object
                item-text="title"
                item-value="id"
                :items="coperative_type_arr"
                :rules="[(v) => !!v || 'Item is required']"
                label="Coperative Type *"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-select
                v-model="state"
                return-object
                item-text="title"
                item-value="id"
                :items="state_arr"
                :rules="[(v) => !!v || 'Item is required']"
                label="Residence State *"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-select
                v-model="lga"
                return-object
                item-text="title"
                item-value="id"
                :items="lga_arr"
                :rules="[(v) => !!v || 'Item is required']"
                label="Residence LGA *"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="address"
                :rules="addressRules"
                label="Coperative Address *"
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mb-3">
        <v-card-title>Contact details</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                v-model="first_name"
                label="Contact First Name *"
                :rules="namesRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                v-model="surname"
                label="Contact Surname *"
                :rules="namesRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                v-model="phone"
                :rules="phoneRules"
                :counter="11"
                label=" Phone Number *"
                maxlength="11"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                v-model="email"
                label="email *"
                :rules="namesRules"
                hint="Must be at least 4 character long"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-row justify="center" align="center">
        <v-col cols="12" sm="12" md="6" class="text-center">
          <v-btn
            rounded
            block
            :loading="loading"
            :disabled="loading"
            color="primary"
            large
            @click="postData()"
          >
            Submit 
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-snackbar v-model="snackbar" right>
      {{ snackbartext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </Layout>
</template>

<script>
import Layout from '@/nfmp_components/layouts/MainPortalLayout'
import httpServ from '../services/HTTPService'

export default {
  components: {
    Layout
  },
  data() {
    return {
      memberData:{},
      currentLOBDetails:{},
      prePostData:{},
      pageData:{},
      snackbar: false,
      snackbartext:'',
      loading: false,
      valid:'',

      company_name:'',
      coperative_type:[],
      coperative_type_arr:[],
      state_arr:[],
      lga_arr:[],
      state:'',
      lga:'',
      address:'',
      first_name:'',
      surname:'',
      phone:'',
      email:'',

      corperativeNameRules: [
        (v) => !!v || 'Corperative name is required',
        (v) =>
          (v && v.length >= 4) ||
        'Corperative name must be greater than 4 characters'
      ],
      namesRules: [
        (v) => !!v || 'Name is required',
        (v) =>
          (v && v.length >= 2) ||
        'Mame must be greater than 2 characters'
      ],
      phoneRules: [
        (v) => !!v || 'Phone Number is required',
        (v) => /^\d{11}$/.test(v) || 'Phone number be must be valid',
        (v) => (v && v.length <= 11) || 'Phone Number must be 11 figures'
      ],
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      addressRules: [
        (v) => !!v || 'Name is required',
        (v) =>
          (v && v.length >= 15) ||
        'Mame must be greater than 15 characters'
      ]
    }
  },
  created() {
    //this.memberData = httpServ.getSessionJson('memberData')
    const formUrl = httpServ.getGuestUrl('/formOptions/farmers/corperatives')
    const resValue2 = httpServ.makeRequest(formUrl, 'GET')

    resValue2.then((result) => {
      console.log('our select is ', result)
      if (result.status === 200) {
        this.state_arr = result.data.state
        this.lga_arr = result.data.lga
        //this.means_of_identification_type_arr = result.data.means_of_identification
        //this.gender_arr = result.data.gender
        //this.lead_type_arr = result.data.leads
        this.commodities_arr = result.data.products
        this.coperative_type_arr = result.data.corperativeType
      }
    })
  },
  mounted() {},
  methods: {
    postData: function() {
      //const postData = this.postStatus === 'new' ? this.getNewData : this.getUpdatedData()
      
      this.loading = true
      setTimeout(() => {
        this.$router.push({ name: 'gruopRegistration' })
        this.snackbartext = 'Your submission was successful'
        this.snackbar = true
        window.location.reload()
      }, 5000)

      /*if (this.$refs.form.validate()) {
        const url = httpServ.getFarmerEnumerationtUrl('/enumeration/drySession/saveFarmer')
        const resp = httpServ.postFetch(url,postData)

        resp.then((result ) => {
          if (result.status === 200) {
            this.snackbartext = 'Your submission was successful'
            this.snackbar = true
          }
        })
      }*/
    }
  }
}
</script>

<style scoped>
</style>
