<template>
  <Layout is-demo>
    <div class="pb-2">
      <h1 class="text-h4">Purchase Report Filing</h1>
      <v-alert dense border="left" type="info">
        To modify an exist entry click on the modify icon
        <v-icon>mdi-file-document-edit</v-icon> on the Action column of the
        record you want modify
      </v-alert>
    </div>
    <v-row dense>
      <v-col cols="12" class="">
        <v-card>
          <v-data-table
            :headers="headers"
            :items="tableData"
            sort-by="id"
            class="elevation-1"
            :loading="tableLoading"
            loading-text="Loading... Please wait"
            fixed-header
            height="350px"
            :no-data-text="tableNoDataText"
          >
            <template v-slot:item.fertilizer="{ item }">
              <span>{{ item.fertilizer | tableLen30 }}</span>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-dialog v-model="dialog" max-width="700px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      class="mb-2 px-2"
                      v-bind="attrs"
                      outlined
                      v-on="on"
                    >
                      Add Purchase Entry
                    </v-btn>
                  </template>
                  <v-card>
                    <v-toolbar color="green" dark dense flat>
                      <v-toolbar-title class="text-h5">
                        {{ formTitle }}
                      </v-toolbar-title>
                    </v-toolbar>
                    <v-form ref="form" v-model="valid" lazy-validation>
                      <small
                        class="pa-2 grey--text"
                      >*indicates required field</small>
                      <v-container>
                        <v-row v-if="mode === 'new'" class="py-1">
                          <v-col cols="12" class="ma-0 pa-0">
                            <v-alert type="info" class="ma-1">
                              Supplier certificate must be verified before you
                              proceed
                            </v-alert></v-col>
                          <v-col cols="12" sm="6" md="6" class="py-0">
                            <v-text-field
                              v-model="supplier_certificate_number"
                              label="Supplier Certificate Number*"
                              :rules="certRules"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6" class="py-0">
                            <v-btn
                              :loading="verifyBtnLoader"
                              :disabled="verifyBtnLoader"
                              large
                              rounded
                              color="green lighten-2"
                              @click="verifyQRCC()"
                            >
                              Verify Supplier's Certificate</v-btn>
                          </v-col>
                          <v-col cols="12" md="6" sm="12" class="py-0">
                            <v-text-field
                              v-model="supplier_name"
                              label="Supplier Name"
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4" sm="12" class="py-0">
                            <v-text-field
                              v-model="QRCC"
                              label="QR Clearance Cert. No."
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="2" sm="12" class="py-0">
                            <v-text-field
                              v-model="supplier_LOB"
                              label="Supplier LOB"
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="12" class="py-0">
                            <v-divider></v-divider>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="6" md="6" class="py-0">
                            <v-select
                              v-model="fertilizer_model"
                              return-object
                              :items="fertilizer_arr"
                              item-text="title"
                              item-value="title"
                              label="Fertilizer*"
                              :rules="[(v) => !!v || 'Fertilizer is required']"
                              :disabled="formDisabled"
                              required
                            ></v-select>
                          </v-col>
                          <v-col cols="12" sm="6" md="6" class="py-0">
                            <v-text-field
                              v-model="grade"
                              label="Grade*"
                              required
                              :disabled="formDisabled"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6" class="py-0">
                            <v-select
                              v-model="package_type_model"
                              return-object
                              :items="package_type_arr"
                              label="Package Type*"
                              :rules="[
                                (v) => !!v || 'Package Type is required',
                              ]"
                              :disabled="formDisabled"
                              required
                            ></v-select>
                          </v-col>
                          <v-col cols="12" sm="6" md="6" class="py-0">
                            <v-text-field
                              v-model="tonnage"
                              label="Tonnage (Metric tonnes/litres)*"
                              required
                              :rules="numberRules"
                              :disabled="formDisabled"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6" class="py-0">
                            <v-menu
                              ref="menu"
                              v-model="menu"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }" >
                                <v-text-field
                                  v-model="transaction_date"
                                  label="Transaction date"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  :disabled="formDisabled"
                                  :rules="dateRules"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="transaction_date"
                                :active-picker.sync="activePicker"
                                :max="
                                  new Date(
                                    Date.now() -
                                      new Date().getTimezoneOffset() * 60000
                                  )
                                    .toISOString()
                                    .substr(0, 10)
                                "
                                :min="min_date"
                                @change="saveDate"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="green darken-1" text @click="close">
                        Close
                      </v-btn>
                      <v-btn
                        color="green darken-1"
                        text
                        outlined
                        :disabled="formDisabled"
                        :loading="formBtnLoader"
                        @click="postFilingData()"
                      >
                        Submit Data
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }" >
              <v-icon
                v-if="isEditOk(item)"
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-file-document-edit
              </v-icon>
            </template>
            <template v-slot:no-data>
              <div class="caption">
                This table has no record! Try reloading it
              </div>
              <v-btn color="primary" outlined small @click="getTableData">
                Reload Data
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col>
        <v-row
          class="py-2"
          align="center"
          justify="space-around"
        >
          <v-btn-toggle>
            <v-btn
              color="primary"
              large
              @click="function(){
                $router.push({ name: 'quaterlyReportFiling' })
              }"
            >
              Back To Quaterly Report Filing
            </v-btn>
            <v-btn
              color="primary"
              large
              @click="function(){
                $router.push({ name: 'salesReportFiling' })
              }"
            >
              View or File Sales Report
            </v-btn>
          </v-btn-toggle>
        </v-row>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" right>
      {{ snackbartext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </Layout>
</template>

<script>
import Layout from '@/nfmp_components/layouts/MainPortalLayout'
//import BackButton from './quaterlyReport/BackButton'
import httpServ from '../services/HTTPService'

export default {
  components: {
    Layout
  },
  data: () => ({
    memberData: {},
    currentLOBDetails: {},
    prePostData: {},
    pageData: {},

    dialog: false,
    dialogDelete: false,
    tableLoading: true,
    singleSelect: true,
    tableNoDataText: 'Thus table has no record!',
    dialog2: false,
    form2Title: '',

    snackbar: false,
    snackbartext: '',

    mode: 'new',

    fertilizer: 0,
    package_type: 0,
    grade: '',
    state_id: '',
    tonnage: '',
    purchase_id: '',
    supplier_certificate_number: '',
    supplier_certificate_number_4update: '',
    exempted_supplier_certificate_number: '',
    supplier_name: '',
    supplier_LOB: '',
    fertilizer_model: {},
    fertilizer_arr: [],
    package_type_model: '',
    package_type_arr: ['Bagged', 'Bulk', 'Liquid'],
    state_id_item: {},
    state_id_arr: [],
    QRCC:'',

    activePicker: null,
    transaction_date: null,
    menu: false,
    min_date: '',

    verifyBtnLoader: false,
    formDisabled: true,
    formBtnLoader: false,
    formDisabled2: true,
    formBtnLoader2: false,
    documentItems: [
      { id: 1, title: 'Import Export Permit Number' },
      { id: 2, title: 'Exemption Permit Number' }
    ],
    documentType_item: {},
    documentType: '',
    documentValue: '',

    valid: false,
    valid2: false,
    filingBtnLoader: false,

    certRules: [
      (v) => !!v || 'Certificate number is required',
      (v) =>
        (v && v.length >= 10) ||
        'Certificate number must be greater than 10 characters'
    ],
    dateRules: [
      (v) => !!v || 'Date is required',
      (v) => (v && Date.parse(v.replaceAll(' ', ''))) || 'Date must be valid'
      //(v) => (v  &&  v instanceof Date && !isNaN(v)) || 'Date must be valid'
    ],
    numberRules: [
      (v) => !!v || 'Tonnage is required',
      (v) => !isNaN(parseFloat(v)) || 'Tonnage must be a number',
      (v) => (v && v > 0) || 'Tonnage must greater than zero'
    ],

    headers: [
      {
        text: 'Fertiilizer',
        align: 'start',
        sortable: false,
        value: 'fertilizer'
      },
      { text: 'Grade', value: 'grade' },
      { text: 'Tonnage(M/T)', value: 'tonnage' },
      { text: 'Supplier', value: 'supplier_certificate_number' },
      { text: 'Approval Status', value: 'approval_status' },
      { text: 'Transaction Date', value: 'transaction_date' },
      { text: 'File Date', value: 'file_date' },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    tableData: [],
    editedIndex: -1
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? 'Add New Purchase Record'
        : 'Modify Existing Record'
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    }
  },
  created() {
    this.memberData = httpServ.getSessionJson('memberData')
    this.currentLOBDetails.memberStatus =
      this.memberData.lobs[this.memberData.displayLOB].membership_status
    this.currentLOBDetails.LOBId =
      this.memberData.lobs[this.memberData.displayLOB].line_of_business_id
    this.currentLOBDetails.LOBname =
      this.memberData.lobs[this.memberData.displayLOB].line_of_business_name
    this.prePostData = {
      membership_id: this.memberData.membership_id,
      LOB_id:
        this.memberData.lobs[this.memberData.displayLOB].line_of_business_id
    }
    this.getTableData()
    this.getPageData()
  },

  methods: {
    getFertilizer: function () {
      //console.log(typeof this.fertilizer_model)
      if (typeof this.fertilizer_model === 'object') {
        return this.fertilizer_model.title
      } else {
        return this.fertilizer_model
      }
    },
    isEditOk: function (item) {
      if (
        item.approval_status === 'Pending' ||
        item.approval_status === 'Invalid'
      ) {
        return true
      } else {
        return false
      }
    },
    saveDate(date) {
      this.$refs.menu.save(date)
    },
    getTableData: function () {
      const postData = this.prePostData

      postData.category = 'purchase'
      const url = httpServ.getTonnageReportUrl(
        '/tonnageReport/member/getPurImpFilingDataTable'
      )
      const resp = httpServ.postFetchWithToken(
        url,
        this.prePostData,
        this.memberData.jwt
      )

      resp.then((result) => {
        this.tableData = result.data
      })
      this.tableLoading = false
    },
    getPageData: function () {
      const url = httpServ.getTonnageReportUrl(
        '/tonnageReport/member/getFilingFormOptions'
      )
      const resp = httpServ.postFetchWithToken(
        url,
        this.prePostData,
        this.memberData.jwt
      )

      resp.then((result) => {
        console.log(result.data)
        this.fertilizer_arr = result.data.fertilizers
        //this.state_id_arr = result.data.states
        this.min_date = result.data.schedules.start_date
      })
    },
    getNewData: function () {
      const data = {
        status: 'new',
        fertilizer: this.getFertilizer(),
        grade: this.grade,
        tonnage: this.tonnage,
        package_type: this.package_type_model,
        supplier_certificate_number: this.supplier_certificate_number,
        transaction_date: this.transaction_date,
        membership_id: this.memberData.membership_id,
        QRCC:this.QRCC,
        line_of_business:
          this.memberData.lobs[this.memberData.displayLOB].line_of_business_id
      }

      return data
    },
    getUpdatedData: function () {
      const data = {
        status: 'update',
        fertilizer: this.getFertilizer(),
        grade: this.grade,
        tonnage: this.tonnage,
        package_type: this.package_type_model,
        supplier_certificate_number: this.supplier_certificate_number_4update,
        transaction_date: this.transaction_date,
        membership_id: this.memberData.membership_id,
        purchase_id: this.purchase_id,
        line_of_business:
          this.memberData.lobs[this.memberData.displayLOB].line_of_business_id
      }

      return data
    },
    postFilingData: function () {
      const postData =
        this.editedIndex > -1 ? this.getUpdatedData() : this.getNewData()

      //validate

      if (this.$refs.form.validate()) {
        this.formBtnLoader = true

        const url = httpServ.getTonnageReportUrl(
          '/tonnageReport/member/savePurchaseFilingDetails'
        )
        const resp = httpServ.postFetchWithToken(
          url,
          postData,
          this.memberData.jwt
        )

        resp.then((result) => {
          if (result.status === 200) {
            this.snackbartext = 'Entry was successful!'
            this.snackbar = true
            this.signinBtnText = 'Successful'
            this.close()
            this.getTableData()
          } else if (result.status === 422) {
            if (result instanceof SyntaxError) {
              // Unexpected token < in JSON
              //console.log('There was a SyntaxError', result)//checker
              this.snackbartext = result.message
              this.snackbar = true
            } else {
              //console.log('There was an error', result) //Checker
              this.snackbartext = result.message
              this.snackbar = true
            }
            this.formBtnLoader = false
          } else {
            this.snackbartext = result.message
            this.snackbar = true
          }
        })
      } else {
        console.log('error')
      }
    },
    editItem(item) {
      this.editedIndex = this.tableData.indexOf(item)
      //console.log(item.fertilizer)
      this.supplier_certificate_number_4update =
        item.supplier_certificate_number
      this.purchase_id = item.id
      this.package_type_model = item.package_type
      this.tonnage = item.tonnage
      this.grade = item.grade
      this.transaction_date = this.$moment(
        item.transaction_date,
        'DD MMM YYYY'
      ).format('YYYY-MM-DD')
      this.fertilizer_model = item.fertilizer
      this.dialog = true
      ///
      this.formDisabled = false
      this.mode = 'update'
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        //this.editedItem = Object.assign({}, this.defaultItem)
        this.supplier_certificate_number = ''
        this.supplier_certificate_number_4update = ''
        this.purchase_id = ''
        this.tonnage = ''
        this.grade = ''
        this.package_type_model = ''
        this.transaction_date = ''
        this.fertilizer_model = ''
        this.editedIndex = -1
        this.verifyBtnLoader = false
        this.formDisabled = true
        this.formBtnLoader = false
        this.mode = 'new'
      })
    },
    verifyQRCC: function () {
      const valid = false
      const url = httpServ.getTonnageReportUrl(
        '/tonnageReport/member/verifyQRCC'
      )

      if (this.supplier_certificate_number === '') {
        this.snackbartext = 'Certificate Number field is empty!'
        this.snackbar = true

        return
      }
      const postData = this.prePostData

      this.verifyBtnLoader = true
      postData.certificate_number = this.supplier_certificate_number
      this.snackbartext = 'Verifing certificate, please wait!'
      this.snackbar = true
      const resp = httpServ.postFetchWithToken(
        url,
        this.prePostData,
        this.memberData.jwt
      )

      resp.then((result) => {
        this.snackbartext = ''
        this.snackbar = false
        this.verifyBtnLoader = false

        //console.log(result)
        if (result.message === 'No record!') {
          this.supplier_name = 'Result not found!'
          this.snackbartext = 'No result was found for your search!'
          this.snackbar = true
        } else {
          this.QRCC = result.data.clearance_certificate_number
          this.supplier_LOB = result.data.line_of_business
          this.supplier_name = result.data.companyName
          this.formDisabled = false
          this.snackbartext = 'Search result OK!'
          this.snackbar = true
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
//TODO update to post data