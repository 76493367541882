<template>
  <Layout is-demo>
    <div class="">
      <h1 class="text-h4">NFMP Invoice</h1>
      <span class="heading-4">Payment Platform: REMITA</span>
      <v-divider class="my-2"></v-divider>
    </div>
    <div id="printMe" rounded-pill class="pa-2" outlined>
      <v-row class="py-2 d-print-block">
        <v-col cols="12" md="8" offset-md="1" >
          <div class="font-weight-bold ">BILL TO:</div>
          <div class="font-weight-light ">{{ pageData.company_name }}</div>
          <!--<div class="font-weight-light ">Company address</div>-->
          <div class="font-weight-light ">{{ pageData.email }}</div>
          <div class="font-weight-light ">{{ pageData.phone }}</div>
        </v-col>
        <v-col cols="12" md="3">
          <qr-code 
            text="testing"
            size="130"
            color="green"
          >
          </qr-code>
        </v-col>
      </v-row>
      <v-row class="py-2 ma-0 d-print-block">
        <v-col v-if="pageData.status==='new'" cols="12" md="3">
          <div class="font-weight-bold ">RRR</div>
          {{ pageData.RRR }}
        </v-col>
        <v-col v-else cols="12" md="3">
          <div class="font-weight-bold ">RRR</div>
          {{ pageData.paymentRef }}
        </v-col>
        <v-col cols="12" md="3">
          <div class="font-weight-bold">Transaction ID</div>
          {{ pageData.transaction_id }}
        </v-col>
        <v-col cols="12" md="3">
          <div class="font-weight-bold">Invoice Number</div>
          {{ pageData.invoiceNumber }}
        </v-col>
        <v-col cols="12" md="3">
          <div class="font-weight-bold">Invoice Date</div>
          {{ pageData.postDate }}
        </v-col>
      </v-row>
      <v-row class="green pa-0 ma-0 d-print-block" outlined>
        <v-col cols="12" md="8" class="font-weight-bold white--text py-1">
          Item Description
        </v-col>
        <v-col cols="12" md="3" class="font-weight-bold white--text py-1">
          Amount(N)
        </v-col>
      </v-row>
      <v-row class="pa-0 ma-0 d-print-block">
        <v-col cols="12" md="8" class="py-1">
          {{ pageData.transaction_type }}
        </v-col>
        <v-col cols="12" md="3" class="py-1">
          {{ pageData.credit }}
        </v-col>
      </v-row>
      <v-row class="green lighten-5 ma-0 pa-0 d-print-block" >
        <v-col md="8" class="py-1">
          <b>Total:</b>
        </v-col>
        <v-col cols="12" md="3" class="py-1">
          <b>{{ pageData.credit }}</b>
        </v-col>
      </v-row>
    </div>
    <v-divider class="my-2"></v-divider>
    <v-row class="d-print-none pt-5">
      <!--<v-row align="center" justify="center">-->
      <v-col cols="12" class="d-flex justify-center">
        <v-btn-toggle rounded>
          <v-btn @click="viewLedger()">View Ledger</v-btn>        
          <v-btn @click="print()">Print Invoice</v-btn>
          <v-btn @click="getRRRStatus()">Verify RRR Status</v-btn>
          <v-btn @click="submitToRemita()">Pay Now With Card</v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    
    <v-snackbar v-model="snackbar" right>
      {{ snackbartext }}
      <template v-slot:action="{ attrs }" >
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Privacy Policy
        </v-card-title>

        <v-card-text>
          {{ dialogMessage }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <form id="remitaForm" ref="form" method="POST">
      <input id="merchantId" name="merchantId" type="hidden"/>
      <input id="hash" name="hash" type="hidden"/>
      <input id="rrr" name="rrr" type="hidden"/>
      <input id="responseurl" name="responseurl" type="hidden"/>
    </form>
  </Layout>
</template>

<script>
//Print
//https://stackoverflow.com/questions/67774394/print-invoice-bill-just-text-in-vue
//https://stackoverflow.com/questions/52343006/how-to-print-a-part-of-a-vue-component-without-losing-the-style
import Layout from '@/nfmp_components/layouts/MainPortalLayout'
import httpServ from '../services/HTTPService'
import sha512 from 'js-sha512'
//import { VPrint } from 'vuetify/lib/components/VPrint'

export default {
  components: {
    Layout
  },
  data() {
    return {
      memberData: {},
      currentLOBDetails: {},
      prePostData: {},
      pageData: {},
      snackbar: false,
      snackbartext:'',
      legderId:0,
      dialog: false,
      dialogMessage: '',
      merchantId:'',
      hash:'',
      rrr:'',
      responseurl:'',
      remitaPaymentURL:'https://remitademo.net/remita/ecomm/finalize.reg'
    }
  },
  created() {
    this.pageData = this.$route.params.obj.item
    this.memberData = httpServ.getSessionJson('memberData')
    this.currentLOBDetails.memberStatus =
      this.memberData.lobs[this.memberData.displayLOB].membership_status
    this.currentLOBDetails.LOBId =
      this.memberData.lobs[this.memberData.displayLOB].line_of_business_id
    this.currentLOBDetails.LOBname =
      this.memberData.lobs[this.memberData.displayLOB].line_of_business_name
    this.prePostData = {
      membership_id: this.memberData.membership_id,
      LOB_id:
        this.memberData.lobs[this.memberData.displayLOB].line_of_business_id,
      ledger_id: this.legderId
    }
  },
  mounted() {

  },
  methods: {
    getRRRStatus: function () {
      const postData = this.prePostData

      postData.RRR = this.pageData.status === 'new' ? this.pageData.RRR : this.pageData.paymentRef
      this.rrr = postData.RRR

      //console.log(postData)
      const url = httpServ.getPaymentUrl('/payment/remita/getStatus')
      const resp = httpServ.postFetchWithToken(
        url,
        postData,
        this.memberData.jwt
      )

      resp.then((result) => {
        const { data } = result

        if (result.status === 200) {
          alert(data.message)
          this.dialog = true
        } else {
          alert(data.message)
        }
      })
    },
    print: function() {
      //this.$htmlToPaper('printMe')
      // Get HTML to print from element
      const prtHtml = document.getElementById('printMe').innerHTML

      // Get all stylesheets HTML
      let stylesHtml = ''

      for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML
      }

      // Open the print window
      const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0')

      WinPrint.document.write(`<!DOCTYPE html>
<html>
  <head>
    ${stylesHtml}
  </head>
  <body>
    ${prtHtml}
    <style type="text/css" media="print">
      @page { size: landscape; }
    </style>
  </body>
</html>`)

      WinPrint.document.close()
      WinPrint.focus()
      WinPrint.print()
      WinPrint.close()
    },
    submitToRemita: function() {
      this.apiKey = '1946'
      this.merchantId = '2547916'
      this.rrr = this.pageData.status === 'new' ? this.pageData.RRR : this.pageData.paymentRef
      this.hash = sha512(this.merchantId + this.rrr + this.apiKey)
      this.responseurl = 'http://localhost:8081/remitaResponse'

      const form = document.getElementById('remitaForm')

      form.elements['merchantId'].value = this.merchantId
      form.elements['rrr'].value = this.rrr
      form.elements['hash'].value = this.hash
      form.elements['responseurl'].value = this.responseurl
      form.action = this.remitaPaymentURL

      const formData = new FormData(form)

      console.log(formData)
      form.submit()
    
    },
    viewLedger: function() {
      this.$router.push({ name: 'ledger' })
    }
  }
}
</script>

<style>
.shipper-name {
  border-top: 3px #000000 solid;
}
.text-center {
  text-align: center;
}
table.table-border,
table.table-border th,
table.table-border td {
  border-collapse: collapse;
  border: 1px #000000 solid;
}
div.page {
  background: white;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5 cm;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
}
div.page[size="A4"] {
  width: 21cm;
  height: 29.7cm;
}
@media print {
  div.page {
    margin: 0;
    box-shadow: 0;
  }
  .no-print,
  .no-print * {
    display: none !important;
  }
}
</style>
//TODO print
//TODO rcode text