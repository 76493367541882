<template>
  <Layout is-demo>
    <div class="">
      <h1 class="text-h4">Clearance Certificate

      </h1>
      <div class="caption" color="grey--text">Reporting Period: {{ reportingPeriod }}</div>
      <v-divider class="my-2"></v-divider>
      <div>
        Quarterly reports are due on or before Fifteen (15) days following the close of the filing period 
        and upon filing of the report shall pay the required inspection fee. If the tonnage report is not filed and the payment of inspection fees is not made within Fifteen (15) days after the due date, a collection fee amounting to ten percent (10%) of the amount due shall be added to the amount due.
      </div>
    </div>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-data-table 
            :headers="headers"
            :items="tableData"
            sort-by="postDate,credit,debit"
            class="elevation-1"
            :loading="tableLoading"
            loading-text="Loading... Please wait"
            fixed-header
            height="350px"
            :no-data-text="tableNoDataText"
          >
            <template v-slot:item.actions="{ item }">
              <v-btn
                v-if="isInvoiceGenOK(item)"
                outlined
                color="green"
                @click="generateInvoice(item)"
              >
                Generate Invoice
              </v-btn>
              <div v-else>
                <v-btn
                  v-if="isInvoiceViewOK(item)"
                  outlined
                  @click="viewInvoice(item)"
                >
                  View Invoice
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-row
          align="center"
          justify="space-around"
        >
          <v-btn-toggle>
            <v-btn text outlined color="primary" @click="callPurchaseReportPage">
              View or Make Purchase Entry
            </v-btn>
            <!--<v-btn text outlined color="primary" @click="callExemptionReportPage">
              View or Make Exemption Entry
            </v-btn>-->
            <v-btn text outlined color="primary" @click="callSalesReportPage">
              View or Make Sales Entry
            </v-btn>
            <v-btn text outlined color="primary" @click="callSalesReportPage">
              Zero filing
            </v-btn>
          </v-btn-toggle>
        </v-row>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" right>
      {{ snackbartext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </Layout>
</template>

<script>
import Layout from '@/nfmp_components/layouts/MainPortalLayout'
//import httpServ from '../services/HTTPService'

export default {
  components: {
    Layout
  },
  data() {
    return {
      memberData:{},
      currentLOBDetails:{},
      prePostData:{},
      pageData:{},
      snackbar: false,

      reportingPeriod :  '01 jan 2023 - 31 jun 2023',
      headers: [
        {
          text: 'FirstName',
          align: 'start',
          sortable: false,
          value: 'first_name'
        }
      ]
    }
  },
  created() {
    //this.memberData = httpServ.getSessionJson('memberData')
  },
  mounted() {},
  methods: {
    callPurchaseReportPage: function() {
      this.$router.push({ name: 'purchaseReportFiling' })
    },
    callExemptionReportPage: function() {
      this.$router.push({ name: 'exemptionReportFiling' })
    },
    callSalesReportPage: function() {
      this.$router.push({ name: 'salesReportFiling' })
    }
  }
}
</script>

<style scoped>
</style>
