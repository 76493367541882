export default [{
  text: '',
  items: [
    { icon: 'mdi-view-dashboard-outline', key: 'menu.dashboard', text: 'Dashboard', link: '/dashboard'  },
    { icon: 'mdi-account-group', key: 'menu.blank', text: 'Group Registration', link: '/groupRegistration' },
    /*{ icon: 'mdi-chart-bell-curve', key: 'menu.blank', text: 'Summary', link: '/summary' },*/
    { icon: 'mdi-nature-people', key: 'menu.blank', text: 'Farmers Registration', link: '/farmersRegistration' },
    /*{ icon: 'mdi-bell', key: 'menu.blank', text: 'Notification', link: '/notification' } ,*/
    { icon: 'mdi-logout', key: 'menu.blank', text: 'Logout', link: '/logout' } 
  ]
}]